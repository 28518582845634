import React from 'react';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {ACCESS_TOKEN_NAME, USER_ID} from '../constants/apiConstants'
import LogoutGoogle from './LogoutGoogle'


import logoIcon from './logo_fontana_bar.png';

import { useHistory } from "react-router-dom";

import SettingsMenu from './SettingsMenu'
import NotificationMenu from './NotificationMenu'
import ChatMenu from './ChatMenu'
import {HOME } from '../constants/apiConstants';

import './MecenateAppBar.css'  


function MecenateAppBar(props) {
  

  

  const token = localStorage.getItem(ACCESS_TOKEN_NAME);
  const history = useHistory();

  const routeChangeProfile = () =>{ 

    const userID = localStorage.getItem(USER_ID);
    //let path = `http://localhost:3001/`+userID; 
    history.push('/'+userID)

    //window.location = HOME+userID
  }

  const routeChangeHome = () =>{ 
    //let path = `http://localhost:3001/` 
    //window.location = HOME
    history.push('/')
  }

 const routeChangeHowitWorks = () => {

  history.push('/'+'howitworks')

 }
 


  return (
    <div className = 'mainAppBar' >
      <AppBar className = 'appBar' color="transparent" position="static" elevation={1} >
        <Toolbar className = 'toolBar' variant ='dense' >
          

        <div className='toolBarContent'>
          <Button onClick = {routeChangeHome}  > <img className = 'logoBar'  src={logoIcon}  alt="Logo_adam"/> </Button>

          <div className = 'howitworks' onClick = {routeChangeHowitWorks}> HOW IT WORKS</div> 
          

          
            
            <div className = 'itemHome'> <Button  onClick = {routeChangeHome}> Home </Button> </div>

            <Button  onClick = {routeChangeProfile} disabled = {!token}> Profile </Button>   

             <ChatMenu active_conversations = {props.active_conversations[1]} unread = {props.unread_messages}/> 

            <LogoutGoogle chatLogout = {props.close_Chat}/>  

            <NotificationMenu/>

            <SettingsMenu className = 'itemHome'/> 
          
         </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default MecenateAppBar;
