import React from 'react';
import { Link } from "react-router-dom";
import './HomeShortcutsCards.css'
import Avatar from '@material-ui/core/Avatar';
import logo from './logoNew2.png';


function HomeShortcutsCards(props) {

  

  return (

    <div className = 'imageContainerShortcut'>

      

          
          <img src= {props.pic} alt="image" className="imageShortcut"/>
     
      
     <div className = 'overlayShortcut'> 

       
       
       <Link to= {props.link} target="_blank" className = 'overlayColorShortcut'>

       <div className = 'overlayTextShortcut'>
         <p className = 'overlayNameShortcut'> {props.text}</p>
         
       </div>

       

       </Link>
       
       
       
  
    </div>

    </div>
  );




}

export default HomeShortcutsCards; 