//export const BASE_URL = 'http://34.219.113.18:8000/';
//export const BASE_URL = 'http://34.219.73.70:8008/';
export const BASE_URL = 'https://dev-api.artmecenate.com/'
//export const BASE_URL = 'https://api.artmecenate.com/';

//export const BASE_URL_WS = 'ws://34.219.113.18:8000/';
//export const BASE_URL_WS = 'ws://34.219.73.70:8008/';
export const BASE_URL_WS = 'wss://dev-api.artmecenate.com/';
//export const BASE_URL_WS = 'wss://api.artmecenate.com/';

//export const HOME = 'http://localhost:3001/'
//export const HOME = 'https://artmecenate.com/'
export const HOME = 'https://dev.artmecenate.com';


export const ACCESS_TOKEN_NAME = 'login_access_token';
export const ACCESS_TOKEN_TIME = 'time_of_last_login_access_token';
export const ACCESS_TICKET_NAME = 'login_access_ticket';
export const USER_ID = 'login_user_id';
export const USER_TYPE = 'login_user_type';
export const WS_OBJECT = 'websocket_object';

export const PSWD_FLAG = 'password_flag';
