import React from 'react';
import { Link } from "react-router-dom";
import './PictureGridCards.css'
import Avatar from '@material-ui/core/Avatar';
import logo from './logoNew2.png';


function PictureGridCards(props) {

  const imageContainerGridCards = {

    gridColumn: props.columns,
    gridRow: props.rows,
    overflow: 'hidden',

    display: 'grid', 
   
   

}



  

  return (

    <div style={imageContainerGridCards}>
    
         <img src= {props.pic} alt="image" className="imageGridCards"/>

         <div className = 'overlayGridCards'> 
           
           <Link to= {props.link} target="_blank" className = 'overlayColorGridCards'>
             <div className = 'overlayTextGridCards'>
               <p className = 'overlayNameGridCards'> {props.text}</p> 
             </div>
           </Link>
           
        </div>

    </div>
  );




}

export default PictureGridCards; 