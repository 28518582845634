import React from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID, USER_TYPE, HOME} from '../constants/apiConstants'




function PaymentPage(props) {

    useEffect(() => {

                axios({
                    method: "post",
                    
                    url: BASE_URL + "payments/checkout",
                    data: {
                            amount: 2000,
                            
                            },
                    headers: {
                
                'Content-Type': 'application/json'
                }
            })
                .then(res => {

                    
                    
                })
                .catch(err => {

                    console.log(err)})

    }, []);
        


  return (

        <div>
          
          <h1> PaymentPage </h1>

       </div >
  );
}

export default PaymentPage;